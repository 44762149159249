@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;600;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Barlow', sans-serif;
}

body {
  background-color: #000;
}

.logo-text {
  font: "Josefin Sans";

}

.center-align {
  text-align: center;
}

.black-bg {
  background: #000;
  color: #fff;
}

.white-bg {
  background: white;
  color: #000;
}

.primary-bg {
  background: #1424E5;
  background: linear-gradient(to right, #1424E5 0%, #09CAFB 100%);
  color: #fff;
}

.h1-hero {
  color: #fff;
  font-size: 66px;
  text-align: left;
  font-weight: bold;
  line-height: 60px;
}

.hero-text {
  color: #fff;
  font-size: 24px;
  text-align: left;
  
}

.h2-azul {
  font-weight: 600;
  background: linear-gradient(to right, #e2dfc4  0%, #78b4ef 100%);
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}


.h1-explore {
 font-weight: bold;
 background: linear-gradient(to right, #09CAFB 0%, #7a979e 100%);
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}

.img-wrap {
  height: 100%;
  max-width: 700px;
  margin: auto;

}

.fit-img {
  width: 100%;

}

.nav-item a:hover .tooltiptext {
  visibility: visible;
}

.nav-item a .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #333 transparent transparent;
}

.btnhero {
  background: linear-gradient(to right, #09CAFB 0%, #7a979e 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
  color: #fff;
}

.newsform {
  display: inline-block;
}

.newsform-message div {
text-align: center;
padding: 10px 0;
}


.newsform input {
  background: #000;
  border-radius: 50px;
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid #333;
  white-space: nowrap;
  padding: 10px 22px;
  color: #ffffff;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.newsform button {
background: #000;
border: 1px solid #000;
padding: 10px 10px;
font-weight: bold;
border-radius: 50px;
-webkit-text-decoration: none;
text-decoration: none;
border: 1px solid #333;
white-space: nowrap;
padding: 10px 22px;
color: #ffffff;
font-size: 1rem;
outline: none;
cursor: pointer;
-webkit-transition: all 0.2s ease-in-out;
transition: all 0.2s ease-in-out;
font-weight: 600;
margin-left: 10px;
}

.newsform button:hover {
  transition: all 0.2s ease-in-out;
  color: #fff;
  box-shadow: rgba(0, 0, 229, 0.38) 0px -3px 30px;
  border: 1px solid #007AF8;
}

.newsform p {
  color: #666;
}


.expand-row {
  width: 100%;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

.gray-text {
  color: gray;
}

.grayier-text {
  color: #666;
}

.text-right {
 text-align: right;
}

.pl {
  padding-left: 20px;
}

.pr {
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .h1-hero {
    font-size: 40px;
    line-height: 40px;
  }

  .newsform form {
    width: 100%;
     }

  .newsform button {
   margin:10px 0;
    }
    
}
